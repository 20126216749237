/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab, Card } from 'react-bootstrap';
import { Alert } from 'rsuite';
import GeneralDataForm from '../Forms/GeneralDataForm';
import RegistryTowersForm from '../Forms/RegistryTowersForm';
import NetworkForm from '../Forms/NetworkForm';
import LockerCompanyForm from '../Forms/LockerCompanyForm';
import CajinLoader from '../Modals/CajinLoader';
import * as titles from '../../constants/titles';
import * as lockerUtils from '../../utils/lockerData';
import * as dates from '../../utils/serviceDays';
import * as actions from '../../actions/lockers';
import './NewLockerTabs.scss';

export default function EditLockerTabs() {
  const location = useSelector((state) => state.location.data);
  const isLoading = useSelector((store) => store.location.isLoading);
  const isError = useSelector((store) => store.location.isError);
  const message = useSelector((store) => store.location.message);
  const [activeKey, setActiveKey] = useState(lockerUtils.GENERAL_TAB);
  const dispatch = useDispatch();
  const generalData = {
    address_str: lockerUtils.formatLockerLocation(location.address),
    phone: location?.phone || '',
    address: location?.address || null,
    parking: location?.address?.parking,
    references: location?.address?.references || '',
    cross_streets: location?.address?.cross_streets || '',
    coords: location?.coords
      ? {
        lat: location.coords.latitude,
        long: location.coords.longitude,
      }
      : null,
    hardware_version: location?.internal_data?.rpi?.version || '',
    company_host: location?.company_host?.id || '',
    internal_name: location?.internal_name,
    ...dates.parseServiceDays(location?.service_days),
  };
  const currentAssignment = lockerUtils.getCompanyAssignment(location.boxes);
  const companyData = {
    operating_model: location?.internal_data?.rpi?.operating_model?.name || '',
    ...currentAssignment,
  };

  const smallCount = lockerUtils.countBoxesOfSize(location.towers, 's', currentAssignment.company);
  const mediumCount = lockerUtils.countBoxesOfSize(location.towers, 'm', currentAssignment.company);
  const largeCount = lockerUtils.countBoxesOfSize(location.towers, 'l', currentAssignment.company);
  const extraLargeCount = lockerUtils.countBoxesOfSize(location.towers, 'xl', currentAssignment.company);

  useEffect(() => {
    if (!isLoading && message && isError) {
      Alert.error(message, 3000);
    }

    if (!isLoading && message && !isError) {
      Alert.success(message, 3000);
    }
  }, [message, isError, isLoading]);

  return (
    <Card>
      {isLoading && <CajinLoader />}
      <h2>{titles.LOCKERS_TAB_EDIT_TITLE}</h2>
      <Tabs activeKey={activeKey} onSelect={setActiveKey}>
        <Tab className="fadeImportant" eventKey={lockerUtils.GENERAL_TAB} title={titles.LOCKERS_TAB_GENERAL}>
          <GeneralDataForm
            onSubmit={(values) => dispatch(actions.updateLocker(location.id, values))}
            initialValues={generalData}
            isEdit
            uuid={location.uuid}
          />
        </Tab>
        <Tab eventKey={lockerUtils.TOWERS_TAB} title={titles.LOCKERS_TAB_TOWERS}>
          <RegistryTowersForm
            onSubmit={(newTowers, deletedTowers) => dispatch(actions.updateLockerTowers(location.id, newTowers, deletedTowers))}
            towers={location.towers}
            isEdit
          />
        </Tab>
        <Tab eventKey={lockerUtils.COMPANY_TAB} title={titles.LOCKERS_TAB_COMPANY}>
          <LockerCompanyForm
            onSubmit={(values) => dispatch(actions.updateLockerCompany(location.id, values, currentAssignment.company))}
            smallCount={smallCount}
            mediumCount={mediumCount}
            largeCount={largeCount}
            extraLargeCount={extraLargeCount}
            initialValues={companyData}
            isEdit
          />
        </Tab>
        <Tab eventKey={lockerUtils.NETWORK_TAB} title={titles.LOCKERS_TAB_NETWORK}>
          <NetworkForm
            onSubmit={(values) => dispatch(actions.updateNetwork(location.id, values))}
            initialValues={location?.internal_data?.network}
            isEdit
          />
        </Tab>
      </Tabs>
    </Card>
  );
}

EditLockerTabs.propTypes = {};
