import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Button, Container, Col,
} from 'react-bootstrap';
import { Icon } from 'rsuite';
import TowerForm from '../Towers/TowerForm';
import * as actions from '../Towers/reducer';
import './RegistryTowersForm.scss';

export default function RegistryTowersForm(props) {
  const {
    onSubmit, onCancel, towers, isEdit,
  } = props;
  const [state, dispatch] = useReducer(actions.reducer, actions.initialState);
  const handleSubmit = () => {
    const newTowers = state.towers.filter((t) => !t.isDisabled).map((t) => t.toJSON());

    onSubmit(newTowers, state.deleteTowers);
  };

  useEffect(() => {
    if (towers && isEdit) {
      // only reset when edit updates towers state
      dispatch(actions.reset(towers));
    }
  }, [towers, isEdit, dispatch]);

  return (
    <Container fluid>
      <Row className="border-gray tower-form__row">
        <Col>
          <Row className="tower-form__towers">
            {state.towers.map((tower, index) => (
              <TowerForm
                key={tower.id}
                index={index}
                onRemove={(id) => dispatch(actions.removeTower(id))}
                onMove={(id, box, pos) => dispatch(actions.moveBox(id, box, pos))}
                setMain={(id) => dispatch(actions.setMainTower(id))}
                onChange={(id, size, count) => dispatch(actions.updateCount(id, size, count))}
                tower={tower}
                canModify={!tower.isDisabled}
                isMainDisabled={state.hasMain}
              />
            ))}
          </Row>
        </Col>
        <Col xs={3} md={2}>
          <Button className="tower-form__add-button" block onClick={() => dispatch(actions.addTower())}>
            <Icon icon="plus" size="lg" />
            <br />
            Añadir torre
          </Button>
        </Col>
      </Row>
      <Row>
        {!isEdit && (
          <Col className="text-right">
            <Button variant="light" size="lg" onClick={onCancel}>
              Regresar
            </Button>
          </Col>
        )}
        <Col className={isEdit ? 'text-right' : 'text-left'}>
          <Button
            variant="primary"
            size="lg"
            onClick={handleSubmit}
            disabled={isEdit && !state.dirty}
          >
            {isEdit ? 'Guardar' : 'Siguiente'}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

RegistryTowersForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isEdit: PropTypes.bool,
  towers: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      is_main: PropTypes.bool.isRequired,
      lockers: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
};
