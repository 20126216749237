import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Redirect } from 'react-router';
import {
  Form, Button, InputGroup, FormControl,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from '../../actions/auth';
import * as msg from '../../constants/errors';
import { ReactComponent as Email } from '../../assets/images/email.svg';
import { ReactComponent as Password } from '../../assets/images/password.svg';

export function LoginForm(props) {
  const {
    onSubmit,
    isLoading,
  } = props;

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: yup.object().shape({
      email: yup.string().email(msg.INVALID_EMAIL).required(msg.REQUIRED_EMAIL),
      password: yup.string().required(msg.REQUIRED_PASSWORD),
    }),
    onSubmit(values, actions) {
      actions.setSubmitting(false);
      actions.setFieldValue('password', '');
      actions.setFieldTouched('password', false);
      onSubmit(values.email, values.password);
    },
  });

  return (
    <Form noValidate data-testid="login-form" onSubmit={formik.handleSubmit} className="Login__form card">
      <Form.Group controlId="loginEmail">
        <Form.Label>Correo</Form.Label>
        <InputGroup size="lg">
          <InputGroup.Prepend>
            <InputGroup.Text><Email /></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="email"
            placeholder="user@chazki.com"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="email"
            value={formik.values.email}
            isInvalid={formik.touched.email && formik.errors.email}
            disabled={isLoading}
          />
          <FormControl.Feedback type="invalid">
            { formik.errors.email }
          </FormControl.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="loginPassword">
        <Form.Label>Contraseña</Form.Label>
        <InputGroup size="lg">
          <InputGroup.Prepend>
            <InputGroup.Text><Password /></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            type="password"
            placeholder="******"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="password"
            value={formik.values.password}
            isInvalid={formik.touched.password && formik.errors.password}
            disabled={isLoading}
          />
          <FormControl.Feedback type="invalid">
            { formik.errors.password }
          </FormControl.Feedback>
        </InputGroup>
      </Form.Group>
      <Button
        type="submit"
        variant="primary"
        block
        size="lg"
        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting || isLoading}
      >
        {
              isLoading
                && <span className="spinner-border" role="status" aria-hidden="true" />
            }
        {
              !isLoading
                && 'Iniciar sesión'
            }
      </Button>
    </Form>
  );
}

LoginForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default function ConnectedAuthForm(props) {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((store) => store.session.authenticated);
  const isLoading = useSelector((store) => store.auth.isLoading);
  const onSubmit = (email, password) => {
    dispatch(loginRequest(email, password));
  };

  if (isAuthenticated) {
    const { redirectTo } = props;
    return <Redirect to={redirectTo} />;
  }

  return (
    <LoginForm
      isLoading={isLoading}
      onSubmit={onSubmit}
    />
  );
}

ConnectedAuthForm.propTypes = {
  redirectTo: PropTypes.string,
};
