export const OPEN_TOWER = 'open_tower';
export const OPEN_BOX = 'open_box';
export const GET_BOX = 'get_door';
export const GET_IR = 'get_ir';
export const CREATE_RESERVATION = 'create_reservation';
export const RESET_TOWER = 'reset_tower';
export const RESET_LOCKER = 'reset_locker';

const timeout = 'No se obtuvo respuesta del locker';
const unknown = 'No se pudo interpretar la respuesta del locker';

export const API_RESPONSES = {
  [OPEN_TOWER]: {
    success: 'Torre abierta correctamente',
    error: 'La torre no abrió correctamente',
    timeout,
    unknown,
  },
  [OPEN_BOX]: {
    success: 'Puerta abierta correctamente',
    error: 'La puerta no abrió correctamente',
    timeout,
    unknown,
  },
  [GET_BOX]: {
    open: 'Locker abierto',
    closed: 'Locker cerrado',
    timeout,
    unknown,
  },
  [GET_IR]: {
    available: 'Locker libre',
    occupied: 'Locker ocupado',
    timeout,
    unknown,
  },
  [RESET_TOWER]: {
    success: 'Módulo reseteado correctamente',
    error: 'Módulo no pudo ser reseteado correctamente',
    timeout,
    unknown,
  },
  [RESET_LOCKER]: {
    success: 'Locker reseteado correctamente',
    error: 'Locker no pudo ser reseteado correctamente',
    timeout,
    unknown,
  },
};

export const OPEN_TOWER_TITLE = 'Abrir torre';
export const OPEN_BOX_TITLE = 'Abrir puerta';
export const GET_BOX_TITLE = 'Consultar cerradura';
export const GET_IR_TITLE = 'Consultar ocupación';
export const CREATE_RESERVATION_TITLE = 'Reservación de prueba';
export const LOCATION_DIALOG_TITLE = 'Administrar locker';
export const LOCATION_DIALOG_SUBTITLE = '¿Qué deseas hacer?';
export const RESERVATION_SUCCESS = 'Reservación exitosa';
export const RESERVATION_ERROR = 'No se pudo crear la reservación';
export const RESET_TOWER_TITLE = 'Reseteo de módulo';
export const RESET_LOCKER_TITLE = 'Reseteo de locker';

export const LOCKERS_CARD_TITLE = 'Registra un nuevo locker';
export const LOCKERS_CARD_TEXT = 'Completa la información para que tu Locker pueda empezar a funcionar';

export const LOCKERS_CARD_BUTTON = 'Registra locker';
export const LOCKERS_TAB_GENERAL = 'Datos generales';
export const LOCKERS_TAB_TOWERS = 'Configuración de torres';
export const LOCKERS_TAB_COMPANY = 'Asignación de compañía';
export const LOCKERS_TAB_NETWORK = 'Configuración de red';
export const LOCKERS_TAB_TITLE = 'Ingresa la información de tu nuevo Locker';
export const LOCKERS_TAB_EDIT_TITLE = 'Edita la información del Locker';
export const LOCKERS_CARD_CONFIRMATION_TITLE = 'Confirma la información de tu nuevo locker';

export const LOCKERS_REGISTRY_TITLE = 'Historial de registros';

export const ADDRESS_REQUIRED = 'La dirección es requerida';
export const ADDRESS_MUST_BE_VALIDATED = 'Es necesario validar la dirección';
export const ADDRESS_MUST_BE_SAVED = 'Es necesario guardar la dirección editada dando clic en el botón guardar';
export const ADDRESS_HELP_TEXT = 'Ingresa calle, número, colonia y código postal. [Máximo 60 caracteres].';
export const ADDRESS_REFERENCES_HELP_TEXT = 'Máximo 35 caracteres.';
export const ADDRESS_CROSS_STREETS_HELP_TEXT = 'Máximo 35 caracteres.';
export const ADDRESS_ERROR_ZERO_RESULTS = 'No se encontró ninguna dirección valida.';

export const LOCKER_NAME_HELP_TEXT = 'Nombre público del locker. 3-50 caracteres.';
export const LOCKER_PHONE_HELP_TEXT = 'Encargado de la sucursal.';
export const INVALID_IP = 'Ingresa una dirección IP válida';
export const INVALID_PHONE = 'Ingresa un teléfono válido. Caracteres especiales permitidos: + - ( )';

export const LOCKER_COMPANY_HELP_TEXT = 'La compañía encargada del hosting.';
export const LOCKER_COMPANY_BOXES_REQUIRED = 'La cantidad de casilleros asignados a la compañía debe ser mayor a cero';
export const LOCKER_MODEL_NETWORK_COMPATIBILITY = 'El modelo seleccionado solo opera en red privada';
export const LOCKER_OVER_CAPACITY_ASSIGNED = 'La cantidad seleccionada supera los casilleros disponibles';
export const LOCKER_PRIVATE_NETWORK = 'Todos los casilleros son de uso exclusivo de una compañía';
export const LOCKER_PUBLIC_NETWORK = 'Todos los casilleros son de uso compartido';
export const LOCKER_MIXED_NETWORK = 'Algunos casilleros son de uso exclusivo';
export const LOCKER_SUCCESS_MESSAGE = 'Cambios guardados exitosamente';
export const LOCKER_CREATE_REQUEST_SUCCEED = 'Locker creado exitosamente';
