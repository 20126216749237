import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, Form, Col, Button, InputGroup,
} from 'react-bootstrap';
import { Icon } from 'rsuite';
import AddressList from './AddressList';
import axios from '../../utils/api';
import * as endpoints from '../../constants/api';
import * as titles from '../../constants/titles';

export default function Address(props) {
  const {
    isTouched, onValidate, isEdit, ...inputProps
  } = props;
  const { id, value, isInvalid } = inputProps;
  const [error, setError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isValidated, setValidated] = useState(false);
  const [results, setResults] = useState([]);
  const [address, setAddress] = useState('');

  const resetState = () => {
    setAddress('');
    setValidated(false);
    setError('');
  };

  const getVariant = () => {
    if (error && !isLoading) {
      return 'warning';
    }
    if (isValidated && !isLoading) {
      return 'success';
    }

    return 'primary';
  };

  const getError = () => {
    let message = '';
    if (error) {
      message = error;
    } else if (isTouched && isInvalid && isEdit !== true) {
      message = titles.ADDRESS_REQUIRED;
    } else if (isTouched && !isValidated && !isLoading) {
      message = titles.ADDRESS_MUST_BE_SAVED;
    }

    return message;
  };

  const selectAddress = (res) => {
    setValidated(true);
    setResults([]);
    setAddress(res.formatted_address);
    onValidate(res);
  };

  const cancelValidation = () => {
    setResults([]);
  };

  const onClick = () => {
    setLoading(true);
    resetState();

    axios.get(endpoints.API_VALIDATE_ADDRESS, {
      params: {
        address: value.replace(/,/, ''),
      },
    })
      .then((response) => {
        if (response.results.length < 1) {
          setError(titles.ADDRESS_ERROR_ZERO_RESULTS);
        } else {
          setError('');
          setResults(response.results);
        }
      })
      .catch(() => {
        setError(titles.ADDRESS_ERROR_ZERO_RESULTS);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSave = async () => {
    setLoading(true);

    try {
      const res = await axios.get(endpoints.API_VALIDATE_ADDRESS, {
        params: {
          address: value.replace(/,/, ''),
        },
      });
      setAddress(res.results[0].formatted_address);
      onValidate(res.results[0]);
      setValidated(false);
    } catch (err) {
      setError(titles.ADDRESS_ERROR_ZERO_RESULTS);
    } finally {
      setLoading(false);
    }
  };

  if (isEdit && !isValidated) {
    return (
      <Form.Row>
        <Col>
          <FormControl
            disabled
            id={id}
            title={address}
            {...inputProps}
          />
        </Col>
        <Col xs="auto">
          <Button
            variant="link"
            className="text-secondary"
            onClick={() => {
              setLoading(true);
              resetState();
              setLoading(false);
              setValidated(true);
            }}
          >
            Editar
          </Button>
        </Col>
      </Form.Row>
    );
  }

  return (
    <>
      <InputGroup>
        <FormControl
          aria-label="Dirección"
          {...inputProps}
        />
        <InputGroup.Append>
          <Button
            variant={isEdit ? 'link' : getVariant()}
            onClick={isEdit ? onSave : onClick}
            disabled={isInvalid || isLoading}
          >
            {
                isLoading
                  && <span className="spinner-border" role="status" aria-hidden="true" />
              }
            {
                error && !isLoading
                  && <Icon icon="exclamation-circle" />
              }
            Validar
          </Button>
        </InputGroup.Append>
      </InputGroup>
      <Form.Text muted>
        { titles.ADDRESS_HELP_TEXT }
      </Form.Text>
      <FormControl.Feedback type="invalid" className="d-block font-weight-bold">
        { getError() }
      </FormControl.Feedback>
      {
        results.length > 0
        && (
        <AddressList
          initialValues={results[0]}
          onSelect={selectAddress}
          onCancel={cancelValidation}
        />
        )
      }
    </>
  );
}

Address.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isTouched: PropTypes.bool,
  onValidate: PropTypes.func,
};
